import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Herosec() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="hero-section page-section scroll-to-page" id="home">
      <div className="custom-container">
        <div className="hero-content content-width">
          <div className="section-header">
            {/* <h4
              className="subtitle scroll-animation"
              data-animation="fade_from_bottom"
              data-aos="fade-up"
            >
              <i className="las la-home"></i> Introduce
            </h4> */}
            {/* At Goallx, we design and develop the future of your brand.
Where innovation meets creativity, your goals are achieved. */}
            <h1 className="scroll-animation" data-aos="fade-up">
              At <span>Goallx</span>, we design and develope the future of your
              brand.
            </h1>
          </div>
          <p className="scroll-animation" data-aos="fade-up" style={{fontSize:18}}>
            We align our expertise with your business goals, driving
            success from launch and beyond. We deliver innovative, scalable
            solutions that support your growth at every stage.{" "}
            <span style={{ fontWeight: "bolder", color: "#05699b" }}>
              You Aim, We Achieve.
            </span>
          </p>
          <a
            href="#portfolio"
            className="go-to-project-btn scroll-to scroll-animation"
            data-aos="fade-up"
          >
            <img src="../assets/images/round-text.png" alt="Rounded Text" />
            <i className="las la-arrow-down"></i>
          </a>
          <div className="facts d-flex">
            <div className="left scroll-animation" data-aos="fade-right">
              <h1>4+</h1>
              <p>
                Years of <br />
                Experience
              </p>
            </div>
            <div className="right scroll-animation" data-aos="fade-left">
              <h1>10+</h1>
              <p>
                projects completed
                {/* <br /> */}
                {/* 15 countries */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
