import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Resume() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
      <div className="custom-container">
        <div className="resume-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-briefcase"></i> Steps
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Project Timeline - <span>From Request To Achievement</span>
            </h1>
          </div>

          <div className="resume-timeline">
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">Initial Consultation</span>
              <h2>
                Once we receive your request, we set up an initial consultation
                to discuss your goals, requirements, and vision.
              </h2>
            </div>
            <div className="item scroll-animation" data-aos="fade-left">
              <span className="date">Proposal & Quote</span>
              <h2>
                After the consultation, we provide a detailed project proposal,
                including timelines and deliverables.
              </h2>
            </div>
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">Design & Development</span>
              <h2>
                With the strategy and structure in place, we move into the
                design and development phase.
              </h2>
            </div>
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">Review & Revisions</span>
              <h2>
                At Goallx we value your feedback!. Therefore you’ll have the
                opportunity to provide input, and we make any necessary
                revisions to ensure the final product exceeds your expectations.
              </h2>
            </div>
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">Launch & Delivery</span>
              <h2>
                It’s time to go live! We handle the entire launch process,
                ensuring a smooth transition from development to the live
                environment.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
