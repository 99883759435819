import React from "react";
// import logo1 from "./assets/images/Logo1.png";

export default function Lsb() {
  return (
    <div className="left-sidebar" style={{ backgroundColor: "#11212d" }}>
      <div className="sidebar-header d-flex align-items-center justify-content-between">
        {/* <img src={logo2} alt="goallx" /> */}
        {/* <span className="designation">You dream, we achieve</span> */}
        <h2 style={{ margin: "auto" }}>You Aim, We Achieve</h2>
      </div>
      <img className="me" src='./assets/images/Logo1.png' alt="goallx" />
      <h2 className="email">contact@goallx.com</h2>
      {/* <h2 className="address">Base in Los Angeles, CA</h2> */}
      <p className="copyright">&copy; 2024 Goallx. All Rights Reserved</p>
      <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
        {/* <li>
          <a href="https://twitter.com/">
            <i className="lab la-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://dribble.com/">
            <i className="lab la-dribbble"></i>
          </a>
        </li> */}
        <li>
          <a href="https://instagram.com/">
            <i className="lab la-instagram"></i>
          </a>
        </li>
        {/* <li>
          <a href="https://github.com/">
            <i className="lab la-github"></i>
          </a>
        </li> */}
      </ul>
      <a href="#contact" className="theme-btn">
        <i className="las la-envelope"></i> Let's talk!
      </a>
    </div>
  );
}
