import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Service() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section
      className="services-area page-section scroll-to-page"
      id="services"
    >
      <div className="custom-container">
        <div className="services-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-stream"></i> Services
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Our <span>Specializations</span>
            </h1>
          </div>

          <div className="services-items">
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-bezier-curve"></i>
              <h2>Website Design</h2>
              <p>We created web based products with unique ideas using Figma</p>
              <span className="projects">10+ Projects</span>
            </div>
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-code"></i>
              <h2>Development</h2>
              <p>
                From concept to deployment, we develop high-performance
                websites.
                <br />
                Your digital presence, delivered with precision.
              </p>
              <span className="projects">10+ Projects</span>
            </div>
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-bezier-curve"></i>
              <h2>Graphic Design</h2>
              <p>
                We craft compelling visual designs, from logos to full brand
                identity, that capture the essence of your business and make an
                impact.
              </p>
              <span className="projects">15+ Projects</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
