import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import emailjs from "emailjs-com";

export default function Contact() {
  useEffect(() => {
    AOS.init({ duration: 1300 });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_vwiopki", // Replace with your EmailJS Service ID
        "template_1jvlbnn", // Replace with your EmailJS Template ID
        e.target,
        "u_YfIJ4PkpTUOGXon" // Replace with your EmailJS User ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send the message, please try again.");
        }
      );
  };

  return (
    <section className="contact-area page-section scroll-content" id="contact">
      <div className="custom-container">
        <div className="contact-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-dollar-sign"></i> contact
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Let's Work <span>Together!</span>
            </h1>
          </div>
          {/* <h3 className="scroll-animation" data-aos="fade-up">
            contact@goallx.com
          </h3> */}
          <form
            className="contact-form scroll-animation"
            data-aos="fade-up"
            onSubmit={sendEmail}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="input-group">
                  <label htmlFor="full-name">
                    Full Name <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="full-name"
                    placeholder="Your Full Name"
                    onChange={handleInputChange}
                    value={formData.name}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <label htmlFor="email">
                    Email <sup>*</sup>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your Email Address"
                    onChange={handleInputChange}
                    value={formData.email}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Write your message here ..."
                    rows="4"
                    onChange={handleInputChange}
                    value={formData.message}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-group submit-btn-wrap">
                  <button className="theme-btn" type="submit">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
